import React from 'react'
import { graphql } from 'gatsby'
import v4 from 'uuid/v4'

import Blocks from '../components/Blocks'
import { Helmet } from 'react-helmet'

const HomePageTemplate = ({data, pageContext}) =>
	<div className="page-template home-page-template">
		<Helmet>
			<title>Ville de Roubaix</title>
			{data.wordpressPage.metadesc && data.wordpressPage.metadesc !== "" &&
				<meta name="description" content={data.wordpressPage.metadesc} />
			}
		</Helmet>
		<div className="offline-fallback-fix-no-idea-why"></div>
		{data.wordpressPage.blocks ?
			data.wordpressPage.blocks.filter(e => e.blockName).map(f =>
				<Blocks key={v4()} {...f} {...pageContext} />
			) :
			<div>
				<Blocks blockName="cgb/block-homebanner" />
				<Blocks blockName="cgb/block-searchsection" />
				<Blocks blockName="cgb/block-homeagenda" />
				<Blocks blockName="cgb/block-hometiles" />
				<Blocks blockName="cgb/block-homeactus" />
			</div>
		}
	</div>

export default HomePageTemplate

export const homepageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			wordpress_parent
			wordpress_id
			metadesc
			blocks{
				blockName
				innerHTML
				attrs{
					description
					title
					category
					buttonlabel
					link
					slug
					className
 					fileUrl
					contactContent
					collapseContent
					headingContent
					downloadContent
					foregroundContent
					informationContent
					mediaId
					mediaMobileId
					level
					backgroundColor
					linkName
					fileName
					alignment
					imageid
					mediaName
					subtitle
					align
					ordered
					keywords
					items {
						title
						slug
						blank
						imageid
						keywords
						link
						full_url
					}
				}
			}
		}
	}
`
